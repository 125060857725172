import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, Stack } from '@mui/material';
// type
import { UploadMultiFileProps, UploadMultiFileWithDividedPreviewProps } from './types';
//
import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';
import MultiFilePreview, { MultiFilePreviewDividedPreview } from './MultiFilePreview';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px Solid #000`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadMultiFile({
  fileName,
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  handleUpload,
  sx,
  ...other
}: UploadMultiFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      <MultiFilePreview
        disabled={other.disabled}
        fileName={fileName}
        files={files}
        showPreview={showPreview}
        onRemove={onRemove}
        onRemoveAll={onRemoveAll}
        handleUpload={handleUpload}
      />

      {helperText && helperText}
    </Box>
  );
}

export function UploadMultiFileWithDividedPreview({
  fileName,
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  handleUpload,
  sx,
  dropzoneSx,
  previewSx,
  ...other
}: UploadMultiFileWithDividedPreviewProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });
  const hasFile = files?.length > 0;
  const { translate: t } = useLocales();

  return (
    <>
      <Grid container spacing={4} sx={{ display: 'flex', ...sx }}>
        <Grid item xs={6} md={6} xl={6} lg={6} sx={{ ...dropzoneSx }}>
          <DropZoneStyle
            {...getRootProps()}
            sx={{
              ...(isDragActive && { opacity: 0.72 }),
              ...((isDragReject || error) && {
                color: 'error.main',
                borderColor: 'error.light',
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <input {...getInputProps()} />

            <BlockContent />
          </DropZoneStyle>
        </Grid>
        <Grid item xs={6} md={6} xl={6} lg={6} sx={{ ...previewSx }}>
          {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

          <MultiFilePreviewDividedPreview
            disabled={other.disabled}
            fileName={fileName}
            files={files}
            showPreview={showPreview}
            onRemove={onRemove}
            onRemoveAll={onRemoveAll}
            handleUpload={handleUpload}
          />

          {helperText && helperText}
        </Grid>
      </Grid>
      {hasFile && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{ mr: 2, mt: 2 }}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            {t('Remove all')}
          </Button>
          <Button size="small" variant="contained" onClick={handleUpload}>
            {t('Upload files')}
          </Button>
        </Stack>
      )}
    </>
  );
}
