import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocation, useNavigate } from 'react-router';
import { useTheme } from '@mui/material';
import { useLocales } from 'src/locales';
import { useSelector } from 'src/redux/store';
import { USER_PERMISSION, staticPermissionList } from 'src/_mock/permission';

const drawerWidth = 200;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
  id?: any;
  isClient?: boolean;
  isSetting?: boolean;
  isCompany?: boolean;
}

export const configureSettingMenuDefaultUrl = (permission: any[]) => {
  const perms = [
    permission.includes(USER_PERMISSION.LC_LIST)
      ? {
          name: 'Location',
          url: `${PATH_DASHBOARD.location.list}`,
          icon: 'carbon:location',
        }
      : null,
    permission.includes(USER_PERMISSION.RL_LIST)
      ? {
          name: 'Role',
          url: `${PATH_DASHBOARD.role.list}`,
          icon: 'icon-park-solid:notes',
        }
      : null,
    permission.includes(USER_PERMISSION.US_LIST)
      ? {
          name: 'User',
          url: `${PATH_DASHBOARD.newUser.list}`,
          icon: 'iconamoon:profile-light',
        }
      : null,
  ]?.filter((v) => v);
  return perms[0]?.url || PATH_DASHBOARD.location.list;
};

export default function SecondarySideBar(props: Props) {
  const { window, id, isClient, isSetting, isCompany } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { translate: t } = useLocales();

  const navigate = useNavigate();

  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const permission = [...useSelector((state) => state.userDetails.permission)].map((obj) => {
    return obj.permissionCode;
  });

  const currentPage = useSelector((state) => state.currentPage)?.currentPage;

  const prospectMenus = [
    {
      name: 'Profile',
      url: `${PATH_DASHBOARD.prospects.profile(id.id)}`,
      icon: 'iconamoon:profile-fill',
    },
    permission.includes(USER_PERMISSION.PR_TRVI)
      ? {
          name: 'Mail Transaction',
          url: `${PATH_DASHBOARD.prospects.mailTranslation(id?.id)}`,
          icon: 'material-symbols-light:mail',
        }
      : null,
    {
      name: 'Activity Log',
      url: `${PATH_DASHBOARD.prospects.activity(id?.id)}`,
      icon: 'ion:analytics-sharp',
    },
  ];

  const clientMenus = [
    {
      name: 'Profile',
      url: `${PATH_DASHBOARD.client.profileView(id.id)}`,
      icon: 'iconamoon:profile-light',
    },
    permission.includes(USER_PERMISSION.CL_NT_LIST)
      ? {
          name: 'Notes',
          url: `${PATH_DASHBOARD.client.list(id?.id)}`,
          icon: 'icon-park-solid:notes',
        }
      : null,
    permission.includes(USER_PERMISSION.CL_TRVI)
      ? {
          name: 'Mail Transaction',
          url: `${PATH_DASHBOARD.client.mailTransaction(id?.id)}`,
          icon: 'material-symbols-light:mail',
        }
      : null,
    {
      name: 'Activity Log',
      url: `${PATH_DASHBOARD.client.activity(id?.id)}`,
      icon: 'ion:analytics-sharp',
    },
  ];

  const settingMenu = [
    permission.includes(USER_PERMISSION.LC_LIST)
      ? {
          name: 'Location',
          url: `${PATH_DASHBOARD.location.list}`,
          icon: 'carbon:location',
        }
      : null,
    permission.includes(USER_PERMISSION.RL_LIST)
      ? {
          name: 'Role',
          url: `${PATH_DASHBOARD.role.list}`,
          icon: 'icon-park-solid:notes',
        }
      : null,
    permission.includes(USER_PERMISSION.US_LIST)
      ? {
          name: 'User',
          url: `${PATH_DASHBOARD.newUser.list}`,
          icon: 'iconamoon:profile-light',
        }
      : null,
    // {
    //   name: 'Template Category',
    //   url: `${PATH_DASHBOARD.templateCategory.list}`,
    //   icon: 'bxs:category-alt',
    // },
  ];

  const companyMenus = [
    {
      name: 'Profile',
      url: `${PATH_DASHBOARD.company.profile.edit(id.id)}`,
      icon: 'iconamoon:profile-fill',
    },
    permission.includes(USER_PERMISSION.CM_TRVI)
      ? {
          name: 'Mail Transaction',
          url: `${PATH_DASHBOARD.company.profile.mailTranslation(id?.id)}`,
          icon: 'material-symbols-light:mail',
        }
      : null,
    permission.includes(USER_PERMISSION.CM_NT_LIST)
      ? {
          name: 'Notes',
          url: `${PATH_DASHBOARD.company.profile.notes.list(id?.id)}`,
          icon: 'icon-park-solid:notes',
        }
      : null,
    permission.includes(USER_PERMISSION.CM_CO_LIST)
      ? {
          name: 'Contacts',
          url: `${PATH_DASHBOARD.company.profile.contacts.list(id?.id)}`,
          icon: 'clarity:users-solid',
        }
      : null,
    permission.includes(USER_PERMISSION.CM_RD_LIST)
      ? {
          name: 'Reminder',
          url: `${PATH_DASHBOARD.company.profile.reminder.list(id?.id)}`,
          icon: 'carbon:reminder',
          // icon: 'gridicons:bell',
        }
      : null,
  ];

  //#region
  // const menusConfig = isSetting ? settingMenu : isClient ? clientMenus : prospectMenus;
  let menusConfig: ({
    name: string;
    url: string;
    icon: string;
  } | null)[] = [];

  if (isSetting) {
    menusConfig = settingMenu;
  } else if (isCompany) {
    menusConfig = companyMenus;
  } else if (isClient) {
    menusConfig = clientMenus;
  } else {
    menusConfig = prospectMenus;
  }

  // const backUrl = isClient
  //   ? PATH_DASHBOARD.client.clientList
  //   : isCompany
  //   ? PATH_DASHBOARD.company.list
  //   : PATH_DASHBOARD.prospects.list;

  // const backLabel = isClient
  //   ? 'Back to client'
  //   : isCompany
  //   ? 'Back to company'
  //   : t('Back to prospects');

  const backUrl = currentPage;

  const backLabel =
    currentPage === PATH_DASHBOARD.client.clientList
      ? 'Back to client companies'
      : currentPage === PATH_DASHBOARD.company.list
      ? 'Back to company'
      : 'Back to prospects';

  // isClient
  //   ? 'Back to client'
  //   : isCompany
  //   ? 'Back to company'
  //   : t('Back to prospects');

  const theme = useTheme();
  //#endregion

  const PRIMARY_DARK = theme.palette.primary.darker;
  const PRIMARY_MAIN = theme.palette.primary.dark;
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;

  // useEffect(() => {
  //   type perm = {
  //     name: string;
  //     code: string;
  //   };

  //   const staticList = staticPermissionList.reduce((prev: perm[], curr) => {
  //     prev = [...prev, ...curr.permissions];
  //     return prev;
  //   }, []);

  //   console.log('static: ', staticList);
  // }, []);

  const drawer = (
    <div>
      <Divider />

      <Divider />
      <List sx={{ pt: '0px', pb: '0px' }}>
        <ListItem disablePadding onClick={() => navigate(backUrl)}>
          <ListItemButton sx={{ pr: 0, pl: '8px' }}>
            <ListItemIcon sx={{ mr: '4px' }}>
              <Iconify color={PRIMARY_MAIN} icon={'eva:arrow-ios-back-fill'} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '0.89rem' }}
              sx={{ minWidth: 'fit-content', color: PRIMARY_MAIN }}
              primary={backLabel}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />

      <List sx={{ pt: '0px', pb: '0px' }}>
        {menusConfig.map((nav: any, index: number) => {
          if (!nav) {
            return null;
          }
          const isActive = location.pathname == nav.url;
          const color = location.pathname == nav.url ? PRIMARY_MAIN : PRIMARY_DARK;
          return (
            <React.Fragment key={index}>
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  navigate(nav.url);
                }}
                sx={{ background: isActive ? '#fff' : '' }}
              >
                <ListItemButton sx={{ pr: 0, pl: '8px' }}>
                  <ListItemIcon sx={{ mr: '4px' }}>
                    <Iconify color={color} icon={nav.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '0.89rem' }}
                    sx={{ minWidth: 'fit-content', color: color, fontSize: '0.89rem' }}
                    primary={nav.name}
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          className="Test"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            position: 'relative !important',
            zIndex: '1 !important',
            background: 'red !important',
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className="Test1"
          style={{ position: 'fixed' }}
          sx={{
            display: { sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              position: 'relative !important',
              background: PRIMARY_LIGHTER,
            },
            position: 'relative',
            height: '90vh',
            zIndex: 1,
          }}
          open
        >
          <div>{drawer}</div>
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <Toolbar />
      </Box>
    </Box>
  );
}
