// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },

  contact: {
    root: path('', PATH_PAGE.comingSoon),
  },

  // Cutomers
  customers: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    list: path(ROOTS_DASHBOARD, '/customers/list'),
  },

  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },

  // template editor
  templateEditor: {
    root: path(ROOTS_DASHBOARD, '/template-editor'),
    viewTemplateEditor: path(ROOTS_DASHBOARD, '/template-editor/list'),
    createTemplateEditor: path(ROOTS_DASHBOARD, '/template-editor/create'),
    editTemplateEditor: path(ROOTS_DASHBOARD, '/template-editor/edit'),
  },

  // templates
  templates: {
    root: path(ROOTS_DASHBOARD, '/templates'),
    viewTemplates: path(ROOTS_DASHBOARD, '/templates/list'),
    createTemplate: path(ROOTS_DASHBOARD, '/templates/create'),
    editTemplate: path(ROOTS_DASHBOARD, '/templates/edit'),
  },

  // template editor new
  templateEditorNew: {
    root: path(ROOTS_DASHBOARD, '/template-editor-new'),
    list: path(ROOTS_DASHBOARD, '/template-editor-new/list'),
    createTemplateEditorNew: path(ROOTS_DASHBOARD, '/template-editor-new/create'),
    edit: path(ROOTS_DASHBOARD, '/template-editor-new/edit'),
  },

  // Prospect
  prospects: {
    root: path(ROOTS_DASHBOARD, '/prospects'),
    create: path(ROOTS_DASHBOARD, '/prospects/create'),
    list: path(ROOTS_DASHBOARD, '/prospects/list'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `/prospects/profile/${id}/view`),
    mailTranslation: (id: string) =>
      path(ROOTS_DASHBOARD, `/prospects/profile/${id}/mail-translation`),
    activity: (id: string) => path(ROOTS_DASHBOARD, `/prospects/profile/${id}/activity-log`),
    status: path(ROOTS_DASHBOARD, '/prospects/status-master'),
    company: {
      root: path(ROOTS_DASHBOARD, '/prospects/company'),
      create: path(ROOTS_DASHBOARD, '/prospects/company/create'),
      list: path(ROOTS_DASHBOARD, '/prospects/company/list'),
      profileView: (id: string) => path(ROOTS_DASHBOARD, `/prospects/company/profile/${id}/view`),
      profileEdit: (id: string) => path(ROOTS_DASHBOARD, `/prospects/company/profile/${id}/edit`),
      mailTranslation: (id: string) =>
        path(ROOTS_DASHBOARD, `/prospects/company/profile/${id}/mail-translation`),
    },
  },
  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    create: path(ROOTS_DASHBOARD, '/client/create'),
    clientList: path(ROOTS_DASHBOARD, '/client/list'),
    profileView: (id: string) => path(ROOTS_DASHBOARD, `/client/profile/${id}/view`),
    mailTransaction: (id: string) => path(ROOTS_DASHBOARD, `/client/profile/${id}/transaction`),
    activity: (id: string) => path(ROOTS_DASHBOARD, `/client/profile/${id}/activity-log`),
    list: (id: string) => path(ROOTS_DASHBOARD, `/client/profile/${id}/notes`),
    view: (id: string, noteId: string) =>
      path(ROOTS_DASHBOARD, `/client/profile/${id}/notes/view/${noteId}`),
    edit: (id: string, noteId: string) =>
      path(ROOTS_DASHBOARD, `/client/profile/${id}/notes/edit/${noteId}`),
    company: {
      root: path(ROOTS_DASHBOARD, '/client/company'),
      create: path(ROOTS_DASHBOARD, '/client/company/create'),
      list: path(ROOTS_DASHBOARD, '/client/company/list'),
      // profileView: (id: string) => path(ROOTS_DASHBOARD, `/client/company/view/${id}`),
      profileView: (id: string) => path(ROOTS_DASHBOARD, `/client/company/profile/${id}/view`),
      profileEdit: (id: string) => path(ROOTS_DASHBOARD, `/client/company/profile/${id}/edit`),
      mailTranslation: (id: string) =>
        path(ROOTS_DASHBOARD, `/client/company/profile/${id}/mail-translation`),
    },
  },
  newUser: {
    root: path(ROOTS_DASHBOARD, '/setting/new-user'),
    list: path(ROOTS_DASHBOARD, '/setting/new-user/list'),
    create: path(ROOTS_DASHBOARD, '/setting/new-user/create'),
    edit: path(ROOTS_DASHBOARD, '/setting/new-user/edit/:id'),
    view: path(ROOTS_DASHBOARD, '/setting/new-user/view/:id'),
  },
  officeMaster: {
    root: path(ROOTS_DASHBOARD, '/setting/office-master'),
    list: path(ROOTS_DASHBOARD, '/setting/office-master/list'),
    create: path(ROOTS_DASHBOARD, '/setting/office-master/create'),
    edit: path(ROOTS_DASHBOARD, '/setting/office-master/edit/:id'),
    view: path(ROOTS_DASHBOARD, '/setting/office-master/view/:id'),
  },

  // Transaction
  transaction: {
    root: path(ROOTS_DASHBOARD, '/transaction'),
    list: path(ROOTS_DASHBOARD, '/transaction/list'),
  },

  // template category
  templateCategory: {
    root: path(ROOTS_DASHBOARD, '/setting/template-category'),
    list: path(ROOTS_DASHBOARD, '/setting/template-category/list'),
    create: path(ROOTS_DASHBOARD, '/setting/template-category/create'),
    edit: path(ROOTS_DASHBOARD, '/setting/template-category/edit/:id'),
    view: path(ROOTS_DASHBOARD, '/setting/template-category/view/:id'),
  },

  setting: {
    root: path(ROOTS_DASHBOARD, '/setting'),
  },
  role: {
    root: path(ROOTS_DASHBOARD, '/setting/role-master'),
    list: path(ROOTS_DASHBOARD, '/setting/role-master/list'),
    create: path(ROOTS_DASHBOARD, '/setting/role-master/create'),
    edit: path(ROOTS_DASHBOARD, '/setting/role-master/edit/:id'),
    view: path(ROOTS_DASHBOARD, '/setting/role-master/view/:id'),
  },

  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    create: path(ROOTS_DASHBOARD, '/company/create'),
    list: path(ROOTS_DASHBOARD, '/company/list'),

    profile: {
      view: (id: string) => path(ROOTS_DASHBOARD, `/company/profile/${id}/view`),
      edit: (id: string) => path(ROOTS_DASHBOARD, `/company/profile/${id}/edit`),
      mailTranslation: (id: string) =>
        path(ROOTS_DASHBOARD, `/company/profile/${id}/mail-translation`),
      notes: {
        // root: (id: string) => path(ROOTS_DASHBOARD, `/company/proofile/${id}/notes`),
        list: (id: string) => path(ROOTS_DASHBOARD, `/company/profile/${id}/notes`),
        view: (id: string, noteId: string) =>
          path(ROOTS_DASHBOARD, `/company/profile/${id}/notes/view/${noteId}`),
        edit: (id: string, noteId: string) =>
          path(ROOTS_DASHBOARD, `/company/profile/${id}/notes/edit/${noteId}`),
      },
      contacts: {
        list: (id: string) => path(ROOTS_DASHBOARD, `/company/profile/${id}/contacts/list`),
      },
      reminder: {
        list: (id: string) => path(ROOTS_DASHBOARD, `/company/profile/${id}/reminder`),
        view: (id: string, reminderId: string) =>
          path(ROOTS_DASHBOARD, `/company/profile/${id}/reminder/view/${reminderId}`),
        edit: (id: string, reminderId: string) =>
          path(ROOTS_DASHBOARD, `/company/profile/${id}/reminder/edit/${reminderId}`),
      },
    },
  },
  location: {
    root: path(ROOTS_DASHBOARD, '/setting/location'),
    list: path(ROOTS_DASHBOARD, '/setting/location/list'),
    create: path(ROOTS_DASHBOARD, '/setting/location/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/setting/location/edit/${id}`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/setting/location/view/${id}`),
  },
  todo: {
    root: path(ROOTS_DASHBOARD, '/todo'),
    create: path(ROOTS_DASHBOARD, '/todo/create'),
    list: path(ROOTS_DASHBOARD, '/todo/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/todo/edit/${id}`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/todo/view/${id}`),
  },
  activity: {
    root: path(ROOTS_DASHBOARD, '/activity-log'),
    list: path(ROOTS_DASHBOARD, '/activity-log/list'),
  },
  files: {
    root : path(ROOTS_DASHBOARD,'/files')
  }
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1';
