import { createSlice } from '@reduxjs/toolkit';
interface InitialStateState {
  userDetail: any;
  permission:any
}
const initialState: InitialStateState = {
  userDetail: {},
  permission:[]
};

const slice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    userDetailFunction(state, action) {
      state.userDetail = action.payload;
    },
    userPermissionFunction(state, action) {
      state.permission = action.payload;
    },
  },
});
export default slice.reducer;

export const { userDetailFunction,userPermissionFunction } = slice.actions;
