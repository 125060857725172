import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer, DialogTitle, IconButton } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config';
import NavDocs from './NavDocs';
import NavAccount from './NavAccount';
import Iconify from 'src/components/iconify';
import { useSelector } from 'src/redux/store';
import { USER_PERMISSION } from 'src/_mock/permission';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();

  // const isDesktop = useResponsive('up', 'lg');
  const isDesktop = false; //for view the menu on teh icon click

  const permission = [...useSelector((state) => state.userDetails.permission)].map((obj) => {
    return obj.permissionCode;
  });
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const NAV_CONFIG = useMemo(() => {
    if (permission.length) {
      navConfig.map((obj: any) => {
        obj.items = obj.items.map((item: any) => {
          // alert(item.title);
          console.log(item.title);
          if (item.title == 'prospects') {
            if (!permission.includes(USER_PERMISSION.PR_LIST)) {
              return null;
            }
          } else if (item.title == 'Clients') {
            if (!permission.includes(USER_PERMISSION.CL_LIST)) {
              return null;
            }
          } else if (item.title === 'transaction') {
            if (!permission.includes(USER_PERMISSION.TR_LIST)) {
              return null;
            }
          } else if (item.title == 'company') {
            if (!permission.includes(USER_PERMISSION.CM_LIST)) {
              return null;
            }
          } else if (item.title === 'todo') {
            if (!permission.includes(USER_PERMISSION.TD_LIST)) {
              return null;
            }
          }
          return item;
        });
        obj.items = obj.items.filter((obj: any) => obj !== null);
        return obj;
      });
    }
    return navConfig;
  }, [permission]);
  // console.log('navConfig====', NAV_CONFIG);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo />

        {/* <NavAccount /> */}
      </Stack>

      <NavSectionVertical data={NAV_CONFIG} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavDocs /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        // width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '2px' }}>
            <DialogTitle sx={{ p: 0 }}>
              <IconButton onClick={onCloseNav}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </DialogTitle>
          </Box>
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
