import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define types
type CopiedValue = string | null;
type CopyFn = (text: string | null) => Promise<boolean>;

type CopyToClipboardContextType = {
  copy: CopyFn;
  copiedText: CopiedValue;
};

// Create the context with default value as undefined
const CopyToClipboardContext = createContext<CopyToClipboardContextType | undefined>(undefined);

// Create a provider component
export const CopyToClipboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      if (text) {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
        return true;
      } else if (text === null) {
        setCopiedText(null)
      }
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return (
    <CopyToClipboardContext.Provider value={{ copiedText, copy }}>
      {children}
    </CopyToClipboardContext.Provider>
  );
};

// Custom hook to use the context
export const useCopyToClipboard = (): CopyToClipboardContextType => {
  const context = useContext(CopyToClipboardContext);
  if (context === undefined) {
    throw new Error('useCopyToClipboard must be used within a CopyToClipboardProvider');
  }
  return context;
};
