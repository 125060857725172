import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userDetailsReducer from './slices/userDetails';
import userAccessReducer from './slices/userAccess';
import userRoleReducer from './slices/userRoles';
import CurrentLocationReducer from './slices/location';
import CurrentPageReducer from './slices/currentPage';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const userDetailsPersistConfig = {
  key: 'userDetails',
  storage,
};
const userRolePersistConfig = {
  key: 'userRole',
  storage,
};
const userAccessPersistConfig = {
  key: 'userAccess',
  storage,
};

const currentLocationPersistConfig = {
  key: 'currentLocation',
  storage,
};

const currentPagePersistConfig = {
  key: 'currentPage',
  storage,
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  userDetails: persistReducer(userDetailsPersistConfig, userDetailsReducer),
  userRole: persistReducer(userRolePersistConfig, userRoleReducer),
  userAccess: persistReducer(userAccessPersistConfig, userAccessReducer),
  currentLocation: persistReducer(currentLocationPersistConfig, CurrentLocationReducer),
  currentPage: persistReducer(currentPagePersistConfig, CurrentPageReducer),
});

export { rootPersistConfig, rootReducer };
