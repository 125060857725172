import { createSlice } from '@reduxjs/toolkit';

interface InitialStateInterface {
  currentPage: any;
}
const initialState: InitialStateInterface = {
  currentPage: '',
};

const slice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    currentPageFunction(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export default slice.reducer;

export const { currentPageFunction } = slice.actions;
