import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
interface LocationSate {
  location: string;
}
const initialState: LocationSate = {
  location: '',
};

const slice = createSlice({
  name: 'currentLocation',
  initialState,
  reducers: {
    currentLocationObj(state, action) {
      state.location = action.payload;
    },
  },
});
export default slice.reducer;

export const { currentLocationObj } = slice.actions;
