import { createContext, useEffect, useReducer, useCallback, useState } from 'react';
// utils
import axios from '../utils/axios';
//
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';
import { useDispatch } from 'src/redux/store';
import { userDetailFunction, userPermissionFunction } from 'src/redux/slices/userDetails';
import { userAccessFunction } from 'src/redux/slices/userAccess';
import { userRoleFunction } from 'src/redux/slices/userRoles';
import { getUserPermissionByRole } from 'src/services/users/Users.service';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const [userRoles, setUserRoles] = useState<any>([]);
  const [userId, setUserId] = useState<number>(0);

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        const user = JSON.parse(localStorage.getItem('user') as string);

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post('/auth/login', {
        username,
        password,
      });

      if (response?.status) {
        const user = response?.data;

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('accessToken', user.token);
        console.log('teh users s ', user);
        reduxDispatch(userDetailFunction(user));
        reduxDispatch(userAccessFunction(user?.userAccess));
        if (user?.userRole?.length) {
          (async () => {
            const res = await getUserPermissionByRole(user?.userRole[0].roleId);
            if (res.status && res?.data?.length) {
              const data = res.data[0].permissions;
              reduxDispatch(userPermissionFunction(data));
            }
          })();
        }

        // reduxDispatch(userPermissionFunction(user?.userAccess));
        setUserRoles(user?.roles);
        setUserId(user.id);

        dispatch({
          type: Types.LOGIN,
          payload: {
            user,
          },
        });
        return response;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  // REGISTER
  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.REGISTER,
      payload: {
        user,
      },
    });
  };

  const clearReduxStore = () => {
    reduxDispatch(userDetailFunction({}));
    reduxDispatch(userRoleFunction([]));
    reduxDispatch(userAccessFunction({}));
    reduxDispatch(userPermissionFunction([]));
  };

  // LOGOUT
  const logout = async () => {
    setSession(null);
    clearReduxStore();
    dispatch({
      type: Types.LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        loginWithGoogle: () => {},
        loginWithGithub: () => {},
        loginWithTwitter: () => {},
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
