import { enUS, frFR, esES, arEG } from '@mui/material/locale';

export const allLanguages = [
  {
    label: 'English',
    value: 'en',
    // systemValue: enUS,
    // icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_en.svg',
  },
  {
    label: 'Spanish',
    value: 'es',
    // systemValue: esES,
    // icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_de.svg',
  },
  {
    label: 'French',
    value: 'fr',
    // systemValue: frFR,
    // icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_fr.svg',
  },
  {
    label: 'Arabic',
    value: 'ar',
    // systemValue: arEG,
    // icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_fr.svg',
  },
];
