import { createSlice } from '@reduxjs/toolkit';
interface InitialStateState {
  userAccess: any;
}
const initialState: InitialStateState = {
  userAccess: {},
};

const slice = createSlice({
  name: 'userAccess',
  initialState,
  reducers: {
    userAccessFunction(state, action) {
      state.userAccess = action.payload;
    },
  },
});
export default slice.reducer;

export const { userAccessFunction } = slice.actions;
