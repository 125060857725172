export enum USER_PERMISSION {
  //#region prospect
  PR_LIST = 'PR_LIST',

  PR_IMPO = 'PR_IMPO',
  PR_CRTE = 'PR_CRTE',
  PR_APST = 'PR_APST',
  PR_UPST = 'PR_UPST',
  PR_SYNC = 'PR_SYNC',

  PR_TRVI = 'PR_TRVI',
  PR_UPTD = 'PR_UPTD',

  PR_MAIL = 'PR_MAIL',
  PR_DELT = 'PR_DELT',
  PR_CTCL = 'PR_CTCL',
  PR_STCH = 'PR_STCH',
  PR_OFCH = 'PR_OFCH',
  //#endregion

  //#region client
  CL_LIST = 'CL_LIST',

  CL_CRTE = 'CL_CRTE',
  CL_DELT = 'CL_DELT',

  CL_TRVI = 'CL_TRVI',
  CL_UPTD = 'CL_UPTD',

  CL_MAIL = 'CL_MAIL',

  CL_NT_UPTD = 'CL_NT_UPTD',
  CL_NT_DELT = 'CL_NT_DELT',
  CL_NT_CRTE = 'CL_NT_CRTE',
  CL_NT_LIST = 'CL_NT_LIST',
  //#endregion

  //#region company
  CM_LIST = 'CM_LIST',

  CM_CRTE = 'CM_CRTE',
  CM_DELT = 'CM_DELT',

  CM_TRVI = 'CM_TRVI',
  CM_UPTD = 'CM_UPTD',

  // company note crud
  CM_NT_UPTD = 'CM_NT_UPTD',
  CM_NT_DELT = 'CM_NT_DELT',
  CM_NT_CRTE = 'CM_NT_CRTE',
  CM_NT_LIST = 'CM_NT_LIST',

  // company contacts
  CM_CO_LIST = 'CM_CO_LIST',

  CM_CO_IMPO = 'CM_CO_IMPO',
  CM_CO_CRTE = 'CM_CO_CRTE',
  CM_CO_APST = 'CM_CO_APST',
  CM_CO_UPST = 'CM_CO_UPST',
  CM_CO_SYNC = 'CM_CO_SYNC',

  CM_CO_TRVI = 'CM_CO_TRVI',
  CM_CO_UPTD = 'CM_CO_UPTD',

  CM_CO_MAIL = 'CM_CO_MAIL',
  CM_CO_DELT = 'CM_CO_DELT',
  CM_CO_CTCL = 'CM_CO_CTCL',
  CM_CO_STCH = 'CM_CO_STCH',
  CM_CO_OFCH = 'CM_CO_OFCH',

  // comany reminder
  CM_RD_UPTD = 'CM_RD_UPTD',
  CM_RD_DELT = 'CM_RD_DELT',
  CM_RD_CRTE = 'CM_RD_CRTE',
  CM_RD_LIST = 'CM_RD_LIST',

  CM_CTCL = 'CM_CTCL',
  //#endregion

  //#region transaction
  TR_LIST = 'TR_LIST',
  //#endregion

  //#region todo
  TD_UPTD = 'TD_UPTD',
  TD_DELT = 'TD_DELT',
  TD_CRTE = 'TD_CRTE',
  TD_LIST = 'TD_LIST',
  //#endregion

  //#region settings

  // location
  LC_UPTD = 'LC_UPTD',
  LC_DELT = 'LC_DELT',
  LC_CRTE = 'LC_CRTE',
  LC_LIST = 'LC_LIST',

  // office (not needed now, because we have location)
  OF_UPTD = 'OF_UPTD',
  OF_DELT = 'OF_DELT',
  OF_CRTE = 'OF_CRTE',
  OF_LIST = 'OF_LIST',

  // role
  RL_UPTD = 'RL_UPTD',
  RL_DELT = 'RL_DELT',
  RL_CRTE = 'RL_CRTE',
  RL_LIST = 'RL_LIST',

  // user
  US_UPTD = 'US_UPTD',
  US_DELT = 'US_DELT',
  US_CRTE = 'US_CRTE',
  US_LIST = 'US_LIST',
  //#endregion
}

export const staticPermissionList: {
  permissionName: string;
  permissions: {
    name: string;
    code: string;
  }[];
}[] = [
  {
    permissionName: 'Prospect',
    permissions: [
      {
        name: 'Prospect List', // view comes with list (maybe)
        code: USER_PERMISSION.PR_LIST,
      },

      // link menu
      {
        name: 'Prospect Import',
        code: USER_PERMISSION.PR_IMPO,
      },
      {
        name: 'Prospect Create',
        code: USER_PERMISSION.PR_CRTE,
      },
      {
        name: 'Prospect Add Status',
        code: USER_PERMISSION.PR_APST,
      },
      {
        name: 'Prospect Update Status',
        code: USER_PERMISSION.PR_UPST,
      },
      {
        name: 'Prospect Sync Now',
        code: USER_PERMISSION.PR_SYNC,
      },

      // form view
      {
        name: 'Prospect Transaction View',
        code: USER_PERMISSION.PR_TRVI,
      },
      {
        name: 'Prospect Update',
        code: USER_PERMISSION.PR_UPTD,
      },

      // top level
      {
        name: 'Prospect Send Mail',
        code: USER_PERMISSION.PR_MAIL,
      },
      {
        name: 'Prospect Delete',
        code: USER_PERMISSION.PR_DELT,
      },
      {
        name: 'Prospect Convert to Client',
        code: USER_PERMISSION.PR_CTCL,
      },
      {
        name: 'Prospect Status Change',
        code: USER_PERMISSION.PR_STCH,
      },
      {
        name: 'Prospect location/office Change',
        code: USER_PERMISSION.PR_OFCH,
      },
    ],
  },
  {
    permissionName: 'Client',
    permissions: [
      {
        // 1
        name: 'Client List',
        code: USER_PERMISSION.CL_LIST,
      },
      {
        // 2
        name: 'Client Create',
        code: USER_PERMISSION.CL_CRTE,
      },

      {
        // 4
        name: 'Client Delete',
        code: USER_PERMISSION.CL_DELT,
      },
      {
        // 3
        name: 'Client Update',
        code: USER_PERMISSION.CL_UPTD,
      },
      {
        // 5
        name: 'Client Transacton View',
        code: USER_PERMISSION.CL_TRVI,
      },
      {
        // 6
        name: 'Client Mail',
        code: USER_PERMISSION.CL_MAIL,
      },

      {
        // 1
        name: 'Client Note List',
        code: USER_PERMISSION.CL_NT_LIST,
      },
      {
        // 2
        name: 'Client Note Create',
        code: USER_PERMISSION.CL_NT_CRTE,
      },
      {
        // 3
        name: 'Client Note Update',
        code: USER_PERMISSION.CL_NT_UPTD,
      },
      {
        // 4
        name: 'Client Note Delete',
        code: USER_PERMISSION.CL_NT_DELT,
      },
    ],
  },

  {
    permissionName: 'Company',
    permissions: [
      {
        // 1
        name: 'Company List',
        code: USER_PERMISSION.CM_LIST,
      },

      // link menu
      {
        // 2
        name: 'Company Create',
        code: USER_PERMISSION.CM_CRTE,
      },

      // form view
      {
        // 3
        name: 'Company Update',
        code: USER_PERMISSION.CM_UPTD,
      },

      {
        name: 'Company Transaction View',
        code: USER_PERMISSION.CM_TRVI,
      },

      // form sidebar
      {
        // 1
        name: 'Company Note List',
        code: USER_PERMISSION.CM_NT_LIST,
      },
      {
        // 2
        name: 'Company Note Create',
        code: USER_PERMISSION.CM_NT_CRTE,
      },
      {
        // 3
        name: 'Company Note Update',
        code: USER_PERMISSION.CM_NT_UPTD,
      },
      {
        // 4
        name: 'Company Note Delete',
        code: USER_PERMISSION.CM_NT_DELT,
      },

      {
        name: 'Company Contacts List',
        code: USER_PERMISSION.CM_CO_LIST,
      },

      {
        name: 'Company Contacts Import',
        code: USER_PERMISSION.CM_CO_IMPO,
      },
      {
        name: 'Company Contacts Create',
        code: USER_PERMISSION.CM_CO_CRTE,
      },
      {
        name: 'Company Contacts Add Status',
        code: USER_PERMISSION.CM_CO_APST,
      },
      {
        name: 'Company Contacts Update Status',
        code: USER_PERMISSION.CM_CO_UPST,
      },
      {
        name: 'Company Contacts Sync Now',
        code: USER_PERMISSION.CM_CO_SYNC,
      },

      {
        name: 'Company Contacts Transaction View',
        code: USER_PERMISSION.CM_CO_TRVI,
      },
      {
        name: 'Company Contacts Update',
        code: USER_PERMISSION.CM_CO_UPTD,
      },

      {
        name: 'Company Contacts Send Mail',
        code: USER_PERMISSION.CM_CO_MAIL,
      },
      {
        name: 'Company Contacts Delete',
        code: USER_PERMISSION.CM_CO_DELT,
      },
      {
        name: 'Company Contacts Convert to Client',
        code: USER_PERMISSION.CM_CO_CTCL,
      },
      {
        name: 'Company Contacts Status Change',
        code: USER_PERMISSION.CM_CO_STCH,
      },
      {
        name: 'Company Contacts location/office Change',
        code: USER_PERMISSION.CM_CO_OFCH,
      },

      {
        // 1
        name: 'Company Reminder List',
        code: USER_PERMISSION.CM_RD_LIST,
      },
      {
        // 2
        name: 'Company Reminder Create',
        code: USER_PERMISSION.CM_RD_CRTE,
      },
      {
        // 3
        name: 'Company Reminder Update',
        code: USER_PERMISSION.CM_RD_UPTD,
      },
      {
        // 4
        name: 'Company Reminder Delete',
        code: USER_PERMISSION.CM_RD_DELT,
      },

      // top level
      {
        name: 'Company Convert to Client',
        code: USER_PERMISSION.CM_CTCL,
      },
      {
        // 4
        name: 'Company Delete',
        code: USER_PERMISSION.CM_DELT,
      },
    ],
  },

  {
    permissionName: 'Transaction',
    permissions: [
      {
        name: 'Transaction List',
        code: USER_PERMISSION.TR_LIST,
      },
    ],
  },

  {
    permissionName: 'Todo',
    permissions: [
      {
        // 1
        name: 'Todo List',
        code: USER_PERMISSION.TD_LIST,
      },
      {
        // 2
        name: 'Todo Create',
        code: USER_PERMISSION.TD_CRTE,
      },
      {
        // 3
        name: 'Todo Update',
        code: USER_PERMISSION.TD_UPTD,
      },
      {
        // 4
        name: 'Todo Delete',
        code: USER_PERMISSION.TD_DELT,
      },
    ],
  },

  //#region settings
  {
    permissionName: 'Location',
    permissions: [
      {
        // 1
        name: 'Location List',
        code: USER_PERMISSION.LC_UPTD,
      },
      {
        // 2
        name: 'Location Create',
        code: USER_PERMISSION.LC_DELT,
      },
      {
        // 3
        name: 'Location Update',
        code: USER_PERMISSION.LC_CRTE,
      },
      {
        // 4
        name: 'Location Delete',
        code: USER_PERMISSION.LC_LIST,
      },
    ],
  },
  {
    permissionName: 'Office',
    permissions: [
      {
        // 1
        name: 'Office List',
        code: USER_PERMISSION.OF_LIST,
      },
      {
        // 2
        name: 'Office Create',
        code: USER_PERMISSION.OF_CRTE,
      },
      {
        // 3
        name: 'Office Update',
        code: USER_PERMISSION.OF_UPTD,
      },
      {
        // 4
        name: 'Office Delete',
        code: USER_PERMISSION.OF_DELT,
      },
    ],
  },
  {
    permissionName: 'Role',
    permissions: [
      {
        // 1
        name: 'Role List',
        code: USER_PERMISSION.RL_LIST,
      },
      {
        // 2
        name: 'Role Create',
        code: USER_PERMISSION.RL_CRTE,
      },
      {
        // 3
        name: 'Role Update',
        code: USER_PERMISSION.RL_UPTD,
      },
      {
        // 4
        name: 'Role Delete',
        code: USER_PERMISSION.RL_DELT,
      },
    ],
  },
  {
    permissionName: 'User',
    permissions: [
      {
        // 1
        name: 'User List',
        code: USER_PERMISSION.US_LIST,
      },
      {
        // 2
        name: 'User Create',
        code: USER_PERMISSION.US_CRTE,
      },
      {
        // 3
        name: 'User Update',
        code: USER_PERMISSION.US_UPTD,
      },
      {
        // 4
        name: 'User Delete',
        code: USER_PERMISSION.US_DELT,
      },
    ],
  },
  //#endregion
];
