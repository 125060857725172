import { useEffect } from 'react';
import { useDispatch } from '../../../redux/store';

import { currentLocationObj } from 'src/redux/slices/location';
// ----------------------------------------------------------------------

export default function CurrentLocationRedux() {
  const dispatch = useDispatch();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position: any) => {
      let location = position.coords.longitude + ',' + position.coords.latitude;
      dispatch(currentLocationObj(location));
    });
  }, []);

  return <></>;
}
