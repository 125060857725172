import React, { createContext, useContext, useState, ReactNode } from 'react';

type NewRecordCreatedContextType = {
  setIsNewReordCreated: (argument: boolean) => void;
  isNewRecordCreated: boolean;
};

const NewRecordCreatedContext = createContext<NewRecordCreatedContextType | undefined>(undefined);

// Create a provider component
export const NewRecordCreatedProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  //   const [copiedText, setCopiedText] = useState<boolean>(false);

  const [isNewRecordCreated, setIsNewRecordCreated] = useState<boolean>(false);

  const setIsNewReordCreated = async (value: boolean) => {
    try {
      if (value === true) {
        setIsNewRecordCreated(true);
      } else if (value === false) {
        setIsNewRecordCreated(false);
      }
    } catch (error) {
      setIsNewRecordCreated(false);
    }
  };

  return (
    <NewRecordCreatedContext.Provider value={{ isNewRecordCreated, setIsNewReordCreated }}>
      {children}
    </NewRecordCreatedContext.Provider>
  );
};

// Custom hook to use the context
export const useIsNewRecordCreated = (): NewRecordCreatedContextType => {
  const context = useContext(NewRecordCreatedContext);
  if (context === undefined) {
    throw new Error('useCopyToClipboard must be used within a CopyToClipboardProvider');
  }
  return context;
};
