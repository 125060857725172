import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  //#region
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  TemplatesList,
  CreateTemplates,
  EditTemplates,
  ProspectList,
  CreateProspect,
  EditProspect,
  ViewProspect,
  ProspectProfile,
  ProspectStatusList,
  MailTranslation,
  ClientNotes,
  ClientNotesPage,
  TemplateEditorNewPage,
  UnsubscribePage,
  NewUserListPage,
  NewUserCreatePage,
  NewUserEditPage,
  RoleListPage,
  RoleEditPage,
  RoleCreatePage,
  // RoleListPage,
  // RoleEditPage,
  // RoleCreatePage,
  ClientCreateForm,
  //#endregion
  ClientMailPage,
  ClientList,
  ClientEditPage,
  // // Client Module
  // ClientListPage,
  // ClientCreatePage,
  OfficeMasterList,
  OfficeMasterCreatePage,
  OfficeMasterEditPage,
  SettingPage,
  TemplateCategoryPage,
  TemplateCategoryListPage,
  TemplateCategoryEditPage,
  EditCompany,
  CompanyList,
  CreateCompany,
  ViewCompany,
  CompanyMailTranslationList,
  CompanyNotesList,
  CompanyContactsListPage,
  CompanyReminderList,
  LocationList,
  LocationCreatePage,
  LocationEditPage,
  TodoList,
  TransactionList,
  ClientListNew,
  ActivityLogPage,
  ActivityLogList,
  TinyMceTemplateEditor,
  FileManagerPageNew,
  ProspectNewList,
} from './elements';
import SecondaryLayout from 'src/pages/components/layouts/secondaryLayout';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },

        // {
        //   path: 'client',
        //   children: [
        //     { element: <Navigate to="/dashboard/client" replace />, index: true },
        //     { path: 'list', element: <ClientListPage /> },
        //     { path: 'create', element: <ClientCreatePage /> },
        //   ],
        // },

        // { path: 'ecommerce', element: <GeneralEcommercePage /> },
        // { path: 'analytics', element: <GeneralAnalyticsPage /> },
        // { path: 'banking', element: <GeneralBankingPage /> },
        // { path: 'booking', element: <GeneralBookingPage /> },
        // { path: 'file', element: <GeneralFilePage /> },
        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShopPage /> },
        //     { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
        //     { path: 'list', element: <EcommerceProductListPage /> },
        //     { path: 'product/new', element: <EcommerceProductCreatePage /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
        //     { path: 'checkout', element: <EcommerceCheckoutPage /> },
        //   ],
        // },

        // this below user is not needed now, can comment it if all good!
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            // { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            // { path: 'account', element: <UserAccountPage /> },
          ],
        },

        // template editor
        {
          path: 'template-editor',
          children: [
            { element: <Navigate to="dashboard/template-editor" replace />, index: true },
            // { path: 'view', element: <MainComponent /> },
            { path: 'list', element: <TemplatesList /> },
            { path: 'create', element: <CreateTemplates /> },
            { path: 'edit/:id', element: <EditTemplates /> },
          ],
        },
        {
          path: 'templates',
          children: [
            { element: <Navigate to="dashboard/templates" replace />, index: true },
            { path: 'list', element: <TemplatesList /> },
            { path: 'create', element: <TinyMceTemplateEditor /> },
            { path: 'edit/:id', element: <TinyMceTemplateEditor /> },
          ],
        },
        {
          path: 'files',
          element: <FileManagerPageNew />,
          // children: [
          //   { element: <Navigate to="dashboard/files" replace />, index: true },
          //   { path: 'list', element: <FileManagerPageNew /> },
          //   { path: 'create', element: <TinyMceTemplateEditor /> },
          //   { path: 'edit/:id', element: <TinyMceTemplateEditor /> },
          // ],
        },

        // prospects
        {
          path: 'prospects',
          children: [
            { element: <Navigate to="dashboard/prospects" replace />, index: true },
            { path: 'list', element: <ProspectNewList /> },
            // { path: 'list', element: <ProspectList /> },
            // { path: 'list-new', element: <ProspectNewList /> },
            { path: 'create', element: <CreateProspect /> },
            // { path: 'edit/:id', element: <EditProspect /> },
            // { path: 'view/:id', element: <ViewProspect /> },
            // { path: 'profile/:id', element: <ProspectProfile /> },
            // { path: 'mail-translation/:id', element: <MailTranslation /> },
            {
              path: 'profile/:id',
              element: <SecondaryLayout />,
              children: [
                { path: 'view', element: <ProspectProfile /> },
                { path: 'mail-translation', element: <MailTranslation /> },
                { path: 'activity-log', element: <ActivityLogPage /> },
              ],
            },
            { path: 'status-master', element: <ProspectStatusList /> },
          ],
        },
        {
          path: 'transaction',
          children: [
            { element: <Navigate to="dashboard/transaction" replace />, index: true },
            { path: 'list', element: <TransactionList /> },
          ],
        },
        {
          path: 'client',
          // element: <SecondaryLayout />,
          children: [
            // { path: 'list', element: <ClientList /> },
            { path: 'list', element: <ClientListNew /> },
            { path: 'create', element: <ClientCreateForm /> },
            // { path: 'edit/:id', element: <ClientForm /> },
            // { path: 'view/:id', element: <ClientForm /> },
            {
              path: 'profile/:id',
              element: <SecondaryLayout isClient />,
              children: [
                { element: <ClientNotes replace />, index: true },
                { path: 'view/:noteId', element: <ClientNotesPage /> },
                { path: 'edit/:noteId', element: <ClientNotesPage /> },
                {
                  path: 'view',
                  element: <ClientEditPage />,
                },
                {
                  path: 'transaction',
                  element: <ClientMailPage />,
                },
                {
                  path: 'notes',
                  children: [{ element: <ClientNotes replace />, index: true }],
                },
                {
                  path: 'activity-log',
                  element: <ActivityLogPage />,
                },
              ],
            },
          ],
        },
        {
          path: 'setting',
          element: <SecondaryLayout isSetting hide />,
          children: [
            {
              path: 'role-master',
              children: [
                { element: <Navigate to="/dashboard/role-master" replace />, index: true },
                { path: 'list', element: <RoleListPage /> },
                { path: 'create', element: <RoleCreatePage /> },
                { path: 'edit/:id', element: <RoleEditPage /> },
                // { path: 'view/:id', element: <NewUserEditPage /> },
              ],
            },
            {
              path: 'office-master',
              children: [
                { element: <Navigate to="/dashboard/office-master" replace />, index: true },
                { path: 'list', element: <OfficeMasterList /> },
                { path: 'create', element: <OfficeMasterCreatePage /> },
                { path: 'edit/:id', element: <OfficeMasterEditPage /> },
              ],
            },
            {
              path: 'template-category',
              children: [
                { element: <Navigate to="/dashboard/template-category" replace />, index: true },
                { path: 'list', element: <TemplateCategoryListPage /> },
                { path: 'create', element: <TemplateCategoryPage /> },
                { path: 'edit/:id', element: <TemplateCategoryEditPage /> },
              ],
            },
            {
              path: 'new-user',
              children: [
                { element: <Navigate to="/dashboard/new-user" replace />, index: true },
                { path: 'list', element: <NewUserListPage /> },
                { path: 'create', element: <NewUserCreatePage /> },
                { path: 'edit/:id', element: <NewUserEditPage /> },
                { path: 'view/:id', element: <NewUserEditPage /> },
              ],
            },
            {
              path: 'location',
              children: [
                { element: <Navigate to="/dashboard/location" replace />, index: true },
                { path: 'list', element: <LocationList /> },
                { path: 'create', element: <LocationCreatePage /> },
                { path: 'edit/:id', element: <LocationEditPage /> },
              ],
            },
          ],
        },

        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },

        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPostsPage /> },
        //     { path: 'post/:title', element: <BlogPostPage /> },
        //     { path: 'new', element: <BlogNewPostPage /> },
        //   ],
        // },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },

        {
          path: 'role-master',
          children: [
            { element: <Navigate to="/dashboard/role-master" replace />, index: true },
            { path: 'list', element: <RoleListPage /> },
            { path: 'create', element: <RoleCreatePage /> },
            { path: 'edit/:id', element: <RoleEditPage /> },
            // { path: 'view/:id', element: <NewUserEditPage /> },
          ],
        },
        {
          path: 'company',
          children: [
            { path: 'list', element: <CompanyList /> },
            { path: 'create', element: <CreateCompany /> },
            {
              path: 'profile/:id',
              element: <SecondaryLayout hide isCompany />,
              children: [
                {
                  path: 'edit',
                  element: <EditCompany />,
                },
                {
                  path: 'view',
                  element: <ViewCompany />,
                },
                { path: 'mail-translation', element: <CompanyMailTranslationList /> },
                {
                  path: 'notes',
                  children: [{ element: <CompanyNotesList replace />, index: true }],
                },
                {
                  path: 'contacts',
                  children: [{ path: 'list', element: <CompanyContactsListPage /> }],
                },
                {
                  path: 'reminder',
                  children: [{ element: <CompanyReminderList replace />, index: true }],
                },
              ],
            },
          ],
        },
        {
          path: 'todo',
          children: [
            // { element: <TodoList replace />, index: true },
            { path: 'list', element: <TodoList /> },
          ],
        },
        {
          path: 'activity-log',
          children: [
            // { element: <TodoList replace />, index: true },
            { path: 'list', element: <ActivityLogList /> },
          ],
        },
      ],
    },

    {
      path: 'unsubscribe',
      children: [
        {
          path: ':type',
          children: [
            {
              path: ':companyId',
              children: [
                {
                  path: ':uuid',
                  element: <UnsubscribePage />,
                },
              ],
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      // element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
