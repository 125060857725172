import axios from 'src/utils/axios';

export const getAllUsers = async (
  pageNo: number,
  pageSize: number,
  systemUsers = false, // if true then crm system users expect client role users will come
  username?: any,
  roleId?: number
) => {
  let query = '';
  if (username) {
    query += `username=${username}&`;
  }
  if (systemUsers) {
    query += `systemUsers=${systemUsers}&`;
  }
  if (roleId) {
    query += `roleId=${roleId}&`;
  }
  query = query.substring(0, query.length - 1);
  return axios.get(`users?pageNo=${pageNo}&pageSize=${pageSize}&${query}`);
};

export const deleteUsersById = async (id?: number) => {
  return axios.delete(`users/${id}`);
};

export const createUsers = async (data: any) => {
  return axios.post(`users`, data);
};

export const getUserById = async (id: number) => {
  return axios.get(`users/${id}`);
};

export const editUsers = async (data: any, id: number) => {
  return axios.put(`users/${id}`, data);
};

export const getUserPermissionByRole = (id: any) => {
  return axios.get(`/role-permission/role?pageNo=0&pageSize=0&roleId=${id}`);
};
