import React, { useEffect, useMemo, useState } from 'react';
import { FormPropsType } from 'src/@types/form-props';
import {
  Box,
  Grid,
  Step,
  Stepper,
  StepLabel,
  StepConnector,
  Card,
  Button,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
  DialogActions,
  DialogProps,
  Stack,
  stepConnectorClasses,
  Tooltip,
  IconButton,
  StepIconProps,
  TextField,
  Switch,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormControl,
  FormHelperText,
  Radio,
  Collapse,
  Autocomplete,
  CardHeader,
  TextareaAutosize,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/iconify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useLocales from 'src/hooks/useLocales';
import * as Yup from 'yup';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PATH_DASHBOARD } from 'src/routes/paths';
import DialogAnimate from 'src/components/animate/DialogAnimateWithoutAnimation';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { AxiosResponseType } from 'src/@types/axios-response';
import {
  createProspect,
  editProspect,
  getAllCountryMaster,
  getAllOfficeMaster,
} from 'src/services/prospect/Prospect.service';
import Emitter from 'src/utils/emitter';
import { useSelector } from 'src/redux/store';
import { USER_PERMISSION } from 'src/_mock/permission';

type Props = {
  onClickEmail?: any;
  onClickDelete?: any;
  onClickMoveToClient?: any;
  isClient?: any;
};

const TopLevelButtons = ({ isClient, hide = false, hideDelete = false }: any) => {
  // const { onClickEmail, onClickDelete, onClickMoveToClient } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const icon = <Iconify icon={'ic:baseline-check-box-outline-blank'} />;
  const checkedIcon = <Iconify icon={'ic:baseline-check-box'} />;
  // console.log('pathname.endsWith(/create): ', pathname.endsWith('/create'));
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { translate: t } = useLocales();
  const [loading, setLoading] = useState<boolean>(false);
  // console.log(
  //   'the permission is ',
  //   useSelector((state) => state?.userDetails)
  // );
  const permission =
    [...useSelector((state) => state?.userDetails?.permission)].map((obj) => {
      return obj.permissionCode;
    }) || [];

  return (
    <>
      <Grid container sx={{ display: 'flex' }} spacing={2}>
        {/* <Grid item></Grid> */}
        {/* Email */}
        {isClient && !hide ? (
          permission.includes(USER_PERMISSION.CL_MAIL) ? (
            <Grid item>
              <Button
                variant="text"
                type="button"
                sx={{
                  mr: 1,
                }}
                disabled={disabled}
                startIcon={<Iconify icon={'mdi:email-outline'} />}
                onClick={() => {
                  Emitter.emit('onClientEmailClick', {});
                }}
              >
                {t('Email')}
              </Button>
            </Grid>
          ) : null
        ) : permission.includes(USER_PERMISSION.PR_MAIL) && !hide ? (
          <Grid item>
            <Button
              variant="text"
              type="button"
              sx={{
                mr: 1,
              }}
              disabled={disabled}
              startIcon={<Iconify icon={'mdi:email-outline'} />}
              onClick={() => {
                Emitter.emit('onEmailClick', {});
              }}
            >
              {t('Email')}
            </Button>
          </Grid>
        ) : null}

        {/* Convert to Client */}
        {!isClient && permission.includes(USER_PERMISSION.PR_CTCL) && !hide ? (
          <Grid item>
            <Button
              variant="text"
              type="button"
              sx={{
                mr: 1,
              }}
              disabled={disabled}
              startIcon={<Iconify icon={'mdi:user-tick-outline'} />}
              onClick={() => {
                // onClickMoveToClient();
                Emitter.emit('onMoveToClintClick', {});
              }}
            >
              {t('Convert to Client')}
            </Button>
          </Grid>
        ) : null}

        {/* Delete */}
        {((!isClient && permission.includes(USER_PERMISSION.PR_DELT)) ||
          permission.includes(USER_PERMISSION.CL_MAIL)) &&
        !hideDelete &&
        !pathname.endsWith('/create') ? (
          <>
            <Grid item>
              <Button
                variant="text"
                type="button"
                sx={{
                  mr: 1,
                }}
                disabled={disabled}
                startIcon={<Iconify icon={'eva:trash-2-outline'} />}
                onClick={() => {
                  Emitter.emit('onDeleteClick', {});
                }}
              >
                {t('Delete')}
              </Button>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default TopLevelButtons;
