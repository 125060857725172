// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import { useContext, useEffect } from 'react';
import jsCookie from 'js-cookie';
import { SettingsContext, useSettingsContext } from './components/settings/SettingsContext';
import i18next from 'i18next';
import { HOST_API_KEY } from './config';

// ----------------------------------------------------------------------

export default function App() {
  const settingContext = useContext(SettingsContext);
  const i18NextLanguage = jsCookie.get('i18next');
  const { onChangeDirectionByLang } = useSettingsContext();

  // const Style = styled(Box)(({ theme }) => ({
  //   '& .MuiOutlinedInput-root': {
  //     '& fieldset': {
  //       borderRadius: `0 0 0 0`,
  //     },
  //     // background: theme.palette.primary.lighter,
  //     // border: (theme:any) => `solid 1px ${theme.palette.grey[500]}`,
  //     border: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
  //     borderRadius: 0,
  //   },
  // }));

  useEffect(() => {
    if (i18NextLanguage) {
      onChangeDirectionByLang(i18NextLanguage);
      //   settingContext.onChangeDirectionByLang(i18NextLanguage);
    }
    i18next.init({
      supportedLngs: ['ar', 'en', 'es', 'fr'],

      // // default language set
      // lng: 'en',
      // fallbackLng: 'en',

      keySeparator: false,
      detection: {
        order: ['cookie', 'navigator', 'htmlTag', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie'],
      },
      backend: {
        loadPath: `${HOST_API_KEY}/translations?lng={{lng}}`,
        allowMultiLoading: true,
      },
    });
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <StyledChart />
              <Router />
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
