import { Grid } from '@mui/material';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import SecondarySideBar from 'src/pages/components/secoundrySidebar/Sidebar';
import TopLevelButtons from './TopLevelButtons';

interface Props {
  isClient?: boolean;
  isSetting?: boolean;
  isCompany?: boolean;
  hide?: boolean;
}

const SecondaryLayout: React.FC<Props> = ({ isClient, isSetting, hide, isCompany }) => {
  const id = useParams<any>();
  const { pathname } = useLocation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2} sx={{ paddingLeft: '0px', marginTop: '2rem' }}>
          <SecondarySideBar
            id={id}
            isClient={isClient}
            isSetting={isSetting}
            isCompany={isCompany}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container xs={12} spacing={2} sx={{ display: 'block' }}>
            <Grid
              item
              xs={12}
              className="ttt"
              // sx={{ marginTop: pathname.endsWith('/create') ? '4.2rem' : '2.2rem' }}
              sx={{ marginTop: '2.2rem' }}
            >
              <TopLevelButtons isClient={isClient} hide={hide} />
            </Grid>
            <Grid item xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SecondaryLayout;
