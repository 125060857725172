import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { USER_ROLES } from 'src/_mock/constant/constant';
interface InitialStateState {
  userRole: any;
  // userCurrentRole: any;
  // multiOrganization: any;
}

// const multiOrgInitialData = {
//   isOrganizationUser: false,
//   isOrganizationSuperAdmin: false,
//   data: null,
//   userMenus: null,
// };

const initialState: InitialStateState = {
  userRole: [],
  // userCurrentRole: {},
  // multiOrganization: multiOrgInitialData,
};

const slice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    userRoleFunction(state, action) {
      state.userRole = action.payload;
    },
    // userCurrentRoleFunction(state, action) {
    //   state.userCurrentRole = action.payload;
    // },
    // multiOrganizationUserRoleReducer(state, action) {
    //   if (action.payload) {
    //     state.multiOrganization = {
    //       isOrganizationUser: action.payload.key === USER_ROLES.ORGANIZATION_USER,
    //       isOrganizationSuperAdmin: action.payload.key === USER_ROLES.ORGANIZATION_SUPER_ADMIN,
    //       data: action.payload,
    //     };
    //   } else {
    //     state.multiOrganization = multiOrgInitialData;
    //   }
    // },
    // organizationAdminsMenus(state, action) {
    //   state.multiOrganization.userMenus = action.payload;
    // },
  },
});
export default slice.reducer;

export const {
  userRoleFunction,
  // userCurrentRoleFunction,
  // multiOrganizationUserRoleReducer,
  // organizationAdminsMenus,
} = slice.actions;
