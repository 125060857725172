import axios from 'axios';
import { dispatch } from 'src/redux/store';
// config
import { HOST_API_KEY } from '../config';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

const serviceConfig = {
  timeout: 45000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create({
  ...serviceConfig,
  baseURL: HOST_API_KEY,
});

axiosInstance.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user') as string);

  const modifiedConfig: any = { ...config };

  let currentLocation = null;
  navigator.geolocation.getCurrentPosition((position: any) => {
    let location = position.coords.longitude + ',' + position.coords.latitude;
    // dispatch(currentLocationObj(location));
    currentLocation = location;
  });

  if (user && user?.token) {
    // eslint-disable-next-line
    modifiedConfig.headers['authtoken'] = user?.token;
    modifiedConfig.headers['location'] = currentLocation;
  }

  return modifiedConfig;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response.headers &&
      response.headers['x-refresh-token'] &&
      response.status &&
      response.status < 400
    ) {
      dispatch({
        type: Types.Login,
        payload: {
          token: response.headers['x-refresh-token'],
        },
      });
    }

    if (response.status && response.data.status) {
      return response.data;
    } else {
      console.log('message:', response.data.error.message);
    }
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
