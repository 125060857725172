import { useTranslation } from 'react-i18next';
// @mui
import { enUS, frFR, esES, arEG } from '@mui/material/locale';
import i18next from '../locales/i18n';
import { allLanguages } from 'src/utils/allLanguages';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = allLanguages.find((_lang) => _lang.value === langStorage) || allLanguages[1];

  const handleChangeLanguage = (newlang: string) => {
    i18next.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: allLanguages,
  };
}
